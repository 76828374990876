// WaGen___HASHKEY__e504df50_2021-08-13 16:28:14 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
 文件信息表

*/
/* ---
标准CRUD方法：添加记录
*/
export function sysFiles_add(entity, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/add',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //SysFiles
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：fileId : String
*/
export function sysFiles_update(entity, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/update',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //SysFiles
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：fileId : String
*/
export function sysFiles_remove(entity, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/remove',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //SysFiles
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function sysFiles_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/selectiveDelete',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //SysFiles
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function sysFiles_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/selectiveUpdate',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //SysFiles
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function sysFiles_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/selectiveAdd',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //SysFiles
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：fileId : String
*/
export function sysFiles_fetch(entity, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/fetch',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //SysFiles
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function sysFiles_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/fetchAll',
		method : 'post',
		headers : {
		},
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function sysFiles_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/selectiveGet',
		method : 'post',
		headers : {
		},
		data : {
			param : entity, //SysFiles
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function sysFiles_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/selectiveGetOne',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //SysFiles
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function sysFiles_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/selectiveCount',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //SysFiles
		}
	})
}

/* ---
多记录插入
*/
export function sysFiles_insertList(list, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/insertList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<SysFiles>
		}
	})
}

/* ---
多记录更新,需要的主键字段：fileId : String
*/
export function sysFiles_updateList(list, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/updateList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<SysFiles>
		}
	})
}

/* ---
多记录删除,需要的主键字段：fileId : String
*/
export function sysFiles_deleteList(list, meta) {
	return fetch({
		url : 'psdmsfiles/fileschema/SysFiles/deleteList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<SysFiles>
		}
	})
}

